import styled from '@emotion/styled'

export default {
  colors: {
    monochrome: `#000`,
    text: `hsla(0,0%,0%,0.9)`,
    background: `#fff`,
    primary: `#7D0552`,
    secondary: `#6CC417`,
    muted: `silver`,
    accent: `secondary`,
    gray: [`gray`, `darkgray`, `silver`, `lightgray`, `gainsboro`, `whitesmoke`],
    header: {
      background: `rgba(255, 255, 255, 0.8)`,
    },
    modes: {
      dark: {
        monochrome: '#fff',
        text: `#fff`,
        background: `hsla(0,0%,0%,0.9)`,
        primary: `#F52887`,
        secondary: `#4EE2EC`,
        muted: `lightgray`,
        accent: `secondary`,
        gray: [`whitesmoke`, `gainsboro`, `lightgray`, `silver`, `darkgray`, `gray`],
        header: {
          background: `rgba(0, 0, 0, 0.8)`,
        },
      },
    },
  },
  fontWeights: {
    bold: 500,
  },
  fontSizes: [14, 16, 18, 20, 24, 32, 48, 60, 64, 72, 96],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  breakpoints: [600, 900, 1200, 1800].map(n => n + `px`),
  sizes: {
    container: {
      fullscreen: `100%`,
      large: `calc(1200px + 20vw)`,
      smallplus: `calc(960px + 10vw)`,
      default: `960px`,
    },
  },
  buttonsCool: {
    display: `block`,
    color: `text`,
    position: `relative`,
    padding: `0.5em 1.5em`,
    appearance: `none`,
    border: `0`,
    background: `transparent`,
    outline: `none`,
    cursor: `pointer`,
    fontWeight: `300`,
    borderRadius: `0`,
    boxShadow: `inset 0 0 0 2px currentcolor`,
    transition: `background 0.8s ease`,
    textAlign: `center`,
    textDecoration: `none`,
    fontWeights: `normal`,
    '&:hover': {
      '.button__vertical': {
        transform: `scaleY(0)`,
      },
      '.button__horizontal': {
        transform: `scaleX(0)`,
      },
    },

    '.button__vertical': {
      position: `absolute`,
      top: `-10px`,
      right: `0`,
      bottom: `-10px`,
      left: `0`,
      transition: `transform 0.8s ease`,
      willChange: `transform`,
      borderLeft: `2px solid currentcolor`,
      borderRight: `2px solid currentcolor`,
      '::before': {
        content: `""`,
        position: `absolute`,
        border: `inherit`,
        top: `10px`,
        bottom: `10px`,
        left: `-12px`,
        right: `-12px`,
      },
    },
    '.button__horizontal': {
      position: `absolute`,
      top: `0`,
      right: `-10px`,
      bottom: `0`,
      left: `-10px`,
      transition: `transform 0.8s ease`,
      willChange: `transform`,
      borderTop: `2px solid currentcolor`,
      borderBottom: `2px solid currentcolor`,
      '::before': {
        content: `""`,
        position: `absolute`,
        border: `inherit`,
        top: `-12px`,
        bottom: `-12px`,
        left: `10px`,
        right: `10px`,
      },
    },
  },

  buttons: {
    color: `text`,
    borderColor: `text`,
    border: `2px solid`,
    bg: `background`,
    px: 4,
    py: 2,
    fontWeight: 700,
    '&.primary': {
      bg: `text`,
      color: `background`,
      textDecoration: 'none',

      '&:hover': {
        bg: `background`,
        color: `text`,
      },
    },
    '&.secondary': {
      bg: 'transparent',
      color: `text`,

      '&:hover': {
        bg: `text`,
        color: `background`,
      },
    },
    '&:hover': {
      color: `background`,
      bg: 'transparent',
      transition: `0.5 ease-out all`,
    },
    large: {
      border: `3px solid`,
      px: 6,
      py: 3,
      fontSize: 5,
      fontWeight: `heading`,
    },
    small: {
      border: `2px solid`,
      borderColor: `text`,
      color: `text`,
      bg: `transparent`,
      textDecoration: `none`,
      px: 2,
      py: 1,
      fontSize: 2,
      fontWeight: `text`,
      ':hover': {
        color: `background`,
        bg: `text`,
      },
    },
  },

  hyperlinksCool: {
    display: 'inline-block',
    a: {
      color: `text`,
      textDecoration: 'none',
      '::after': {
        bg: `text`,
        content: `""`,
        display: `block`,
        height: `2px`,
        marginTop: `-5px`,
        transition: `width .7s`,
        width: `100%`,
      },
      ':active': {
        color: `text`,
        textDecoration: `none`,
      },
      ':visited': {
        color: `text`,
        textDecoration: `none`,
      },
    },
    '&:hover': {
      a: {
        '::after': {
          width: `0`,
        },
      },
    },
  },
  hyperlinksCoolMenu: {
    display: `block`,
    ml: 3,
    pt: 1,
    pb: 2,
    a: {
      fontWeight: 400,
      fontSize: 5,
      textDecoration: `none`,
      color: `text`,
      ':hover': {
        textDecoration: `underline`,
        transition: `1s ease-in all`,
      },
      '&.active': {
        textDecoration: `underline`,
      },
      ':visited': {
        color: `text`,
      },
    },
    '@media (min-width: 900px)': {
      display: 'inline-block',
      ml: 3,
      a: {
        color: `text`,
        ml: 0,
        pt: 0,
        pb: 0,
        fontSize: 4,
        textDecoration: 'none',
        '::after': {
          bg: `text`,
          content: `" "`,
          display: `block`,
          height: `2px`,
          marginTop: `0`,
          marginLeft: `-1px`,
          transition: `width .7s`,
          width: `100%`,
        },
        '&.active': {
          textDecoration: `none`,
          '&:after': {
            width: 0,
          }
        },
        '&:visited': {
          a: {
            color: `text`,
            textDecoration: `none`,
          }
        },
      },
      '&:hover': {
        a: {
          textDecoration: `none`,
          '::after': {
            width: `0px`,
          },
        },
      },
    },
  },

  // maybe convert in one
  hyperlinksCoolInvert: {
    display: 'inline-block',
    a: {
      color: `background`,
      textDecoration: 'none',
      '::after': {
        background: `background`,
        content: `""`,
        display: `block`,
        height: `2px`,
        marginTop: `-5px`,
        transition: `.7s`,
        width: `100%`,
      },
      ':active': {
        color: `background`,
        textDecoration: `none`,
      },
      ':visited': {
        color: `background`,
        textDecoration: `none`,
      },
    },
    '&:hover': {
      a: {
        '::after': {
          width: `0`,
        },
      },
    },
  },
  headlineCool: {
    display: 'inline-block',
    mb: 0,
    '::after': {
      content: '""',
      bg: `background`,
      display: 'block',
      width: 0,
      height: '2px',
      transition: 'width 0.7s',
    },
  },
  slider: {
    '::before': {
      background:
        'linear-gradient(90deg, currentColor 25%, rgba(0,0,0,0) 100%, rgba(0,0,0,1) 100%)',
      bg: 'transparent',
      content: '""',
      height: '100%',
      width: '50px',
      '@media (min-width: 600px)': {
        width: '150px',
      },
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: '3',
    },
    '::after': {
      background:
        'linear-gradient(270deg, currentColor 25%, rgba(0,0,0,0) 100%, rgba(0,0,0,1) 100%)',
      content: '""',
      height: '100%',
      width: '50px',
      '@media (min-width: 600px)': {
        width: '150px',
      },
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: '3',
    },
    '.slick-dots': {
      bottom: '30px',
      li: {
        button: {
          height: '30px',
          width: '30px',
          '::before': {
            bg: `monochrome`,
            border: '5px solid',
            borderColor: `background`,
            borderRadius: '50%',
            color: 'transparent',
            fontSize: '25px',
            opacity: '1',
          },
        },
        '&.slick-active': {
          button: {
            '::before': {
              background: `background`,
              bg: 'background',
            },
          },
        },
      },
    },
  },

  // Available grids
  leftToRight: {
    display: `grid`,
    gridTemplateColumns: `1fr`,
    gridGap: `0`,
    alignItems: `center`,
    my: `2rem`,
    '@media (min-width: 900px)': {
      gridTemplateColumns: `1fr 1fr`,
    },
    '@media (min-width: 1200px)': {
      my: `6rem`,
    },
  },
  rightToLeft: {
    display: `grid`,
    gridTemplateColumns: `1fr`,
    gridGap: `0`,
    alignItems: `center`,
    my: `2rem`,
    'div:first-of-type': {
      order: 2,
    },
    '@media (min-width: 900px)': {
      gridTemplateColumns: `1fr 1fr`,
    },
    '@media (min-width: 1200px)': {
      my: `6rem`,
    },
  },
  centered: {
    display: `grid`,
    gridTemplateColumns: `1fr`,
    gridGap: `0`,
    alignItems: `center`,
    my: `2rem`,
    '@media (min-width: 900px)': {
      gridTemplateColumns: `repeat(6, 1fr)`,
      div: {
        ':first-of-type': {
          gridColumnStart: `2`,
          gridColumnEnd: `6`,
        },
      },
    },
    '@media (min-width: 1200px)': {
      my: `0rem`,
    },
  },
  oneColumn: {
    display: `grid`,
    gridTemplateColumns: `1fr`,
    my: `0rem`,
    '@media (min-width: 1200px)': {
      my: `0rem`,
    },
  },
  twoColumn: {
    display: `grid`,
    gridTemplateColumns: `1fr`,
    gridGap: `0`,
    alignItems: `center`,
    my: `0rem`,
    '@media (min-width: 900px)': {
      gridTemplateColumns: `1fr 1fr`,
    },
    '@media (min-width: 1200px)': {
      my: `0rem`,
    },
  },
  iconGrid: {
    display: `inline-grid`,
    gridTemplateColumns: `60px 1fr`,
    alignItems: `center`,
    justifyItems: `center`,
  },
  threeColumn: {
    display: `grid`,
    gridTemplateColumns: `1fr`,
    gridGap: `20px`,
    alignItems: `center`,
    my: `0rem`,
    '@media (min-width: 900px)': {
      gridTemplateColumns: `1fr 1fr`,
    },
    '@media (min-width: 1200px)': {
      gridTemplateColumns: `1fr 1fr 1fr`,
      my: `0rem`,
    },
  },
  fourColumn: {
    display: `grid`,
    gridTemplateColumns: `1fr`,
    gridGap: `0`,
    alignItems: `center`,
    my: `2rem`,
    '@media (min-width: 900px)': {
      gridTemplateColumns: `1fr 1fr 1fr 1fr`,
      div: {
        ':first-of-type': {
          gridColumnStart: `2`,
          gridColumnEnd: `4`,
        },
      },
    },
    '@media (min-width: 1200px)': {
      my: `0rem`,
    },
  },

  styles: {
    h1: {
      fontSize: [48, 48, 48, 70],
      fontWeight: 700,
      fontFamily: `Amiri`,
    },
    h2: {
      fontSize: [36, 36, 36],
      fontWeight: 700,
      fontFamily: `Amiri`,
      mt: '1.45rem',
    },
    h3: {
      fontSize: [31, 31, 31],
      fontWeight: 300,
    },
    responsiveSizes: {
      h1: {
        fontSize: [27,30,48,70],
      },
      h2: {
        fontSize: [23,26,36,36],
      },
      h3: {
        fontSize: [20,22,31,31],
      },
    },
    display: {
      fontWeight: 700,
      fontSize: [6, 7, 8],
    },
    displayLight: {
      fontWeight: 700,
      fontSize: [5, 6, 7],
    },
    a: {
      fontWeight: `400`,
      textDecoration: `none`,
      color: `text`,
      ':hover': {
        textDecoration: `underline`,
      },
      ':visited': {
        color: `text`,
      },
    },
    navlink: {
      ml: 3,
      pt: 1,
      fontWeight: 400,
      textDecoration: `none`,
      color: `text`,
      borderBottom: `3px solid`,
      borderColor: `background`,
      transition: `1s ease-in all`,
      ':hover': {
        color: `primary`,
        transition: `1s ease-in all`,
      },
      '&.active': {
        color: `text`,
        borderColor: `text`,
        transition: `1s ease-in all`,
      },
      ':visited': {
        color: `text`,
      },
    },
    burgerlink: {
      display: `block`,
      ml: 3,
      pt: 1,
      pb: 2,
      fontWeight: 400,
      fontSize: 5,
      textDecoration: `none`,
      color: `text`,
      ':hover': {
        textDecoration: `underline`,
        transition: `1s ease-in all`,
      },
      '&.active': {
        textDecoration: `underline`,
      },
      ':visited': {
        color: `text`,
      },
    },
    padding: {
      p: [`0 1rem`, , `0 2rem`],
    },
    headerSpaces: {
      display: `grid`,
      gridTemplateColumns: [`120px 1fr`, null, `250px 1fr`],
      m: `0 auto`,
      p: [`1rem`, null, `1rem 2rem`],

      '@media (min-width: 2500px)': {
        p: `1rem 0`,
      },
    },
    reference: {
      tile: {
        height: [240, 320, 320, 400],
      },
      image: {
        height: [240, 320, 320, 400],
      },
    },
  },
}
