// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agentur-legal-dataprotection-jsx": () => import("./../src/pages/agentur/legal/dataprotection.jsx" /* webpackChunkName: "component---src-pages-agentur-legal-dataprotection-jsx" */),
  "component---src-pages-agentur-legal-imprint-js": () => import("./../src/pages/agentur/legal/imprint.js" /* webpackChunkName: "component---src-pages-agentur-legal-imprint-js" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-page-template-js": () => import("./../src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-project-template-js": () => import("./../src/templates/projectTemplate.js" /* webpackChunkName: "component---src-templates-project-template-js" */)
}

